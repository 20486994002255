/*! modernizr 3.6.0 (Custom Build) | MIT | 'touchevents' swapped for 'touch' for backward compatability *
 * https://modernizr.com/download/?-csstransforms3d-placeholder-touchevents !*/
!(function (e, t, n) {
    function r(e, t) {
        return typeof e === t;
    }
    function o() {
        var e, t, n, o, s, i, l;
        for (var a in g)
            if (g.hasOwnProperty(a)) {
                if (
                    ((e = []),
                    (t = g[a]),
                    t.name &&
                        (e.push(t.name.toLowerCase()), t.options && t.options.aliases && t.options.aliases.length))
                )
                    for (n = 0; n < t.options.aliases.length; n++) e.push(t.options.aliases[n].toLowerCase());
                for (o = r(t.fn, "function") ? t.fn() : t.fn, s = 0; s < e.length; s++)
                    (i = e[s]),
                        (l = i.split(".")),
                        1 === l.length
                            ? (Modernizr[l[0]] = o)
                            : (!Modernizr[l[0]] ||
                                  Modernizr[l[0]] instanceof Boolean ||
                                  (Modernizr[l[0]] = new Boolean(Modernizr[l[0]])),
                              (Modernizr[l[0]][l[1]] = o)),
                        S.push((o ? "" : "no-") + l.join("-"));
            }
    }
    function s() {
        return "function" != typeof t.createElement
            ? t.createElement(arguments[0])
            : z
            ? t.createElementNS.call(t, "http://www.w3.org/2000/svg", arguments[0])
            : t.createElement.apply(t, arguments);
    }
    function i() {
        var e = t.body;
        return e || ((e = s(z ? "svg" : "body")), (e.fake = !0)), e;
    }
    function l(e, n, r, o) {
        var l,
            a,
            u,
            f,
            p = "modernizr",
            d = s("div"),
            c = i();
        if (parseInt(r, 10)) for (; r--; ) (u = s("div")), (u.id = o ? o[r] : p + (r + 1)), d.appendChild(u);
        return (
            (l = s("style")),
            (l.type = "text/css"),
            (l.id = "s" + p),
            (c.fake ? c : d).appendChild(l),
            c.appendChild(d),
            l.styleSheet ? (l.styleSheet.cssText = e) : l.appendChild(t.createTextNode(e)),
            (d.id = p),
            c.fake &&
                ((c.style.background = ""),
                (c.style.overflow = "hidden"),
                (f = T.style.overflow),
                (T.style.overflow = "hidden"),
                T.appendChild(c)),
            (a = n(d, e)),
            c.fake
                ? (c.parentNode.removeChild(c), (T.style.overflow = f), T.offsetHeight)
                : d.parentNode.removeChild(d),
            !!a
        );
    }
    function a(e) {
        return e
            .replace(/([a-z])-([a-z])/g, function (e, t, n) {
                return t + n.toUpperCase();
            })
            .replace(/^-/, "");
    }
    function u(e, t) {
        return !!~("" + e).indexOf(t);
    }
    function f(e, t) {
        return function () {
            return e.apply(t, arguments);
        };
    }
    function p(e, t, n) {
        var o;
        for (var s in e) if (e[s] in t) return n === !1 ? e[s] : ((o = t[e[s]]), r(o, "function") ? f(o, n || t) : o);
        return !1;
    }
    function d(t, n, r) {
        var o;
        if ("getComputedStyle" in e) {
            o = getComputedStyle.call(e, t, n);
            var s = e.console;
            if (null !== o) r && (o = o.getPropertyValue(r));
            else if (s) {
                var i = s.error ? "error" : "log";
                s[i].call(s, "getComputedStyle returning null, its possible modernizr test results are inaccurate");
            }
        } else o = !n && t.currentStyle && t.currentStyle[r];
        return o;
    }
    function c(e) {
        return e
            .replace(/([A-Z])/g, function (e, t) {
                return "-" + t.toLowerCase();
            })
            .replace(/^ms-/, "-ms-");
    }
    function m(t, r) {
        var o = t.length;
        if ("CSS" in e && "supports" in e.CSS) {
            for (; o--; ) if (e.CSS.supports(c(t[o]), r)) return !0;
            return !1;
        }
        if ("CSSSupportsRule" in e) {
            for (var s = []; o--; ) s.push("(" + c(t[o]) + ":" + r + ")");
            return (
                (s = s.join(" or ")),
                l("@supports (" + s + ") { #modernizr { position: absolute; } }", function (e) {
                    return "absolute" == d(e, null, "position");
                })
            );
        }
        return n;
    }
    function h(e, t, o, i) {
        function l() {
            p && (delete A.style, delete A.modElem);
        }
        if (((i = r(i, "undefined") ? !1 : i), !r(o, "undefined"))) {
            var f = m(e, o);
            if (!r(f, "undefined")) return f;
        }
        for (var p, d, c, h, v, y = ["modernizr", "tspan", "samp"]; !A.style && y.length; )
            (p = !0), (A.modElem = s(y.shift())), (A.style = A.modElem.style);
        for (c = e.length, d = 0; c > d; d++)
            if (((h = e[d]), (v = A.style[h]), u(h, "-") && (h = a(h)), A.style[h] !== n)) {
                if (i || r(o, "undefined")) return l(), "pfx" == t ? h : !0;
                try {
                    A.style[h] = o;
                } catch (g) {}
                if (A.style[h] != v) return l(), "pfx" == t ? h : !0;
            }
        return l(), !1;
    }
    function v(e, t, n, o, s) {
        var i = e.charAt(0).toUpperCase() + e.slice(1),
            l = (e + " " + E.join(i + " ") + i).split(" ");
        return r(t, "string") || r(t, "undefined")
            ? h(l, t, o, s)
            : ((l = (e + " " + k.join(i + " ") + i).split(" ")), p(l, t, n));
    }
    function y(e, t, r) {
        return v(e, n, n, t, r);
    }
    var g = [],
        C = {
            _version: "3.6.0",
            _config: { classPrefix: "", enableClasses: !0, enableJSClass: !0, usePrefixes: !0 },
            _q: [],
            on: function (e, t) {
                var n = this;
                setTimeout(function () {
                    t(n[e]);
                }, 0);
            },
            addTest: function (e, t, n) {
                g.push({ name: e, fn: t, options: n });
            },
            addAsyncTest: function (e) {
                g.push({ name: null, fn: e });
            },
        },
        Modernizr = function () {};
    (Modernizr.prototype = C), (Modernizr = new Modernizr());
    var S = [],
        w = C._config.usePrefixes ? " -webkit- -moz- -o- -ms- ".split(" ") : ["", ""];
    C._prefixes = w;
    var x = "CSS" in e && "supports" in e.CSS,
        _ = "supportsCSS" in e;
    Modernizr.addTest("supports", x || _);
    var T = t.documentElement,
        z = "svg" === T.nodeName.toLowerCase();
    Modernizr.addTest("placeholder", "placeholder" in s("input") && "placeholder" in s("textarea"));
    var b = (C.testStyles = l);
    Modernizr.addTest("touch", function () {
        var n;
        if ("ontouchstart" in e || (e.DocumentTouch && t instanceof DocumentTouch)) n = !0;
        else {
            var r = [
                "@media (",
                w.join("touch-enabled),("),
                "heartz",
                ")",
                "{#modernizr{top:9px;position:absolute}}",
            ].join("");
            b(r, function (e) {
                n = 9 === e.offsetTop;
            });
        }
        return n;
    });
    var P = "Moz O ms Webkit",
        E = C._config.usePrefixes ? P.split(" ") : [];
    C._cssomPrefixes = E;
    var k = C._config.usePrefixes ? P.toLowerCase().split(" ") : [];
    C._domPrefixes = k;
    var j = { elem: s("modernizr") };
    Modernizr._q.push(function () {
        delete j.elem;
    });
    var A = { style: j.elem.style };
    Modernizr._q.unshift(function () {
        delete A.style;
    }),
        (C.testAllProps = v),
        (C.testAllProps = y),
        Modernizr.addTest("csstransforms3d", function () {
            return !!y("perspective", "1px", !0);
        }),
        o(),
        delete C.addTest,
        delete C.addAsyncTest;
    for (var N = 0; N < Modernizr._q.length; N++) Modernizr._q[N]();
    e.Modernizr = Modernizr;
})(window, document);
